import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { notification, Modal, Input } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Flex, Text } from 'theme-ui'
import { Dispatch } from '../../../redux/store'
import track from '../../../common/track'
import theme from '../../../common/theme'

const DeleteAssetConfirmation = ({
  selectedAssetId,
  assetDisplayName,
  clearCurrentAsset,
  handleConfirmationClose,
}) => {
  const dispatch: Dispatch = useDispatch()
  const [doubleConfirmations, setDoubleConfirmations] = useState('')
  return (
    <Modal
      title={
        <Flex>
          <Text
            sx={{ color: theme.stylebook.icons.modals.danger.iconColor, mr: 2 }}
          >
            <CloseCircleOutlined translate="no" />
          </Text>
          Delete asset
        </Flex>
      }
      visible
      okText="Yes"
      okType="danger"
      okButtonProps={{ disabled: doubleConfirmations !== assetDisplayName }}
      cancelText="No"
      onOk={async () => {
        const result = await dispatch.Assets.deleteAsset(selectedAssetId)
        if (result.result === 'success') {
          notification.success({
            message: 'Success',
            description: `Asset ${assetDisplayName} has been deleted.`,
          })
          handleConfirmationClose(false)
          clearCurrentAsset()
          track.event('ASSET_DELETED', { assetId: selectedAssetId })
        } else {
          notification.error({
            message: 'Error',
            description: `There was a problem with deleting ${assetDisplayName}. Please try again later.`,
          })
        }
      }}
      onCancel={handleConfirmationClose}
      maskClosable
    >
      <Flex sx={{ flexDirection: 'column', gap: 2 }}>
        <Text>Are you sure you want to delete the asset?</Text>
        <Text>
          Please type `<strong>{assetDisplayName}</strong>` to confirm:
        </Text>
        <Input
          placeholder={assetDisplayName}
          onChange={(e) => {
            setDoubleConfirmations(e.target.value)
          }}
        />
      </Flex>
    </Modal>
  )
}

export default DeleteAssetConfirmation
