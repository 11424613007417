export enum RoleType {
  Owner = 'Owner',
  Admin = 'Admin',
  Member = 'Member',
  ReadOnly = 'ReadOnly',
}

export const RoleDisplayText = {
  [RoleType.Owner]: 'Owner',
  [RoleType.Admin]: 'Admin',
  [RoleType.Member]: 'Member',
  [RoleType.ReadOnly]: 'Read Only',
}

export enum PermissionType {
  AssetDelete = 'Asset.Delete',
  AssetIngest = 'Asset.Ingest',
  ScanInsightsRead = 'Asset.ReadScanInsights',
}

export const getViewRoles = () => Object.values(RoleType)

export const getViewRolesWithoutOwner = (): Array<RoleType> =>
  getViewRoles().filter((r) => r !== RoleType.Owner)
